import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import authorizerSlice from 'src/authorizer/authorizerSlice';

import { m } from 'src/util/cssUtil';
import styles from 'src/components/adminPage/AdminPage.module.css';
import egainLogo from 'src/img/egainLogo.png';

export default function AdminHeader() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const firstName = useSelector(authorizerSlice.select.firstName);
  let firstLetter = '';
  if (firstName !== null) {
    firstLetter = firstName.charAt(0);
  }

  return (
    <div className={m(styles.topBar, 'd-flex', 'align-items-center')}>
      <div
        className={m(
          styles.marginX,
          'd-flex',
          'justify-content-between',
          'align-items-center',
          'gx-0'
        )}
      >
        {/* This wrapper div is used so the logo can be aligned to the center without stretching*/}
        <div>
          <img src={egainLogo} className={styles.headerLogo} alt="eGain Logo" />
        </div>
        {firstName !== null && (
          <div>
            <span
              style={{ fontFamily: 'Helvetica Neue Black', fontSize: '18px' }}
            >
              {t('welcome')}, {firstName}
            </span>
            <button
              onClick={() => navigate('/myaccount/accountInformation')}
              className={styles.profileLogo}
            >
              {firstLetter}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
