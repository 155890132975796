import { lazy } from 'react';
import { useLocation, Outlet } from "react-router";

import TinyPage from 'src/components/tinyPage/TinyPage';

const NotFound = lazy(() => import(
  /* webpackChunkName: "NotFound" */
  /* webpackPrefetch: true */
  'src/pages/notfound/NotFound'));

export default function Root() {
  const location = useLocation();

  return location.pathname === '/'
    ? <TinyPage><NotFound /></TinyPage>
    : <Outlet />;
}